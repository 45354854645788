<template>
  <section>
    <vue-headful :title="title" />
    <v-app-bar
    fixed
    color="transparent"
    flat
    class="navBarColor"
    >
     <v-app-bar-nav-icon sticky color="#fff" class="drawerDashboard" @click.stop="drawerDashboard = !drawerDashboard"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      dark
      v-model="drawerDashboard"
     color="uniColor"
      app
      prominent
    >
      <v-list-item class="px-2">
        <v-list-item-content>
         <v-list-item-avatar  class="pa-md-6 mx-lg-auto avatar"  height="92" width="80" tile>
           <v-img class="px-2" src="../assets/img/logoNic.png"></v-img>
          </v-list-item-avatar>
          <!-- <v-list-item-subtitle> Network international center
          </v-list-item-subtitle> -->
           <!-- <v-btn
            icon
            @click.stop="drawerDashboard = !drawerDashboard"
          >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense nav >
        <v-list-item 
          v-for="item in items"
          :key="item.title"
          :to="{ name: item.to }"
          link
          active-class="blue darken-4 "
        >
          <v-list-item-icon>
            <template>
              <v-icon class="white--text" :title="item.title">{{ item.icon }}</v-icon>
            </template>
          </v-list-item-icon>
          <v-list-item-content>
            <template>
              <v-list-item-title class="white--text" :title="item.title">{{
                item.title
              }}</v-list-item-title>
            </template>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  link active-class="indigo lighten-1">
          <v-list-item-icon>
            <v-icon class="white--text" @click="toCompra" :title="toCompra">mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text" @click="toCompra" :title='"Comprar nuevo dominio"'
            >Comprar nuevo dominio</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
         <v-list-item  link active-class="indigo lighten-1">
          <v-list-item-icon>
            <v-icon class="white--text" v-on:click="logOut" :title="logOut">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text" v-on:click="logOut" :title='"Cerrar sesion"'
              >Cerrar sesión</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>
<script>
import vueHeadful from "vue-headful";
import { mapState } from "vuex";
export default {
  components: {
    vueHeadful,
  },
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mini: true,
    drawerDashboard: true,
    hover: false,
    title: "Panel de Control",
    group: null,
    right: null,
    items: [
      {
        icon: "mdi-network",
        title: "Dominios",
        to: "admin-dominios",
        onclick: "",
      },
      {
        icon: "mdi-account",
        title: "Información de cuenta",
        to: "admin-cuenta",
        onclick: "",
      },
      {
        icon: "mdi-book-open",
        title: "Términos y condiciones",
        to: "admin-terminoscondiciones",
        onclick: "",
      },
    ],
    
  }),
  computed: {
    ...mapState(["barColor"]),
    drawer: {
      get() {
        return this.$store.state.drawerDashboard;
      },
      set(val) {
        this.$store.commit("SET_drawerDashboard", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
  },
  methods: {
    logOut: function () {
      window.localStorage.clear();
      window.location.href = "/";
    },
    toCompra: function () {
      window.open('../buscardominio', '_blank');
    }
    ,
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>
<style scoped>
.adminP {
  color: #616161 !important;
}
.panel_admin {
  margin-top: -20px;
  margin-bottom: -20px;
}
@media (max-width: 725px) {
  .panel_admin {
    height: 5vh !important;
  }
  .drawerDashboard{
    display: block !important;
  }
}
.drawerDashboard{
  display: none !important;
}
.bg {
  background:rgba(28, 55, 146, .85) !important;
}
.panel_control {
  margin-top: 20px;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
} 
@media (max-width: 725px) {
  .drawerDashboard{
    display:inline-block !important;
  }
  .avatar{
    height: 112px !important;
    
  }
  .navBarColor{
    background-color: #003791 !important;
  }
}
</style>
